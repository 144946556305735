import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Stack, Text, Divider, Button, HStack } from 'native-base';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PlanSummaryCard, FeedingGuidelinesCard } from '.';
import segment from '../../../segment';
import { DeceasedDogsView } from '../DeceasedDogsView';
import { AddNewDogButton } from './AddNewDogButton';
import { DogTabSkeleton } from './DogTabSkeleton';

import { INACTIVE_STATUSES, Pet, PetStatus } from '@/api';
import {
  EditableCard,
  PortalWrapper,
  DogPictoGroup,
  AvatarImageLarge,
} from '@/components/Elements';
import { PillCarousel } from '@/components/Elements/PillCarousel';
import { AddOnCarousel } from '@/components/Portal/AddOnShopping';
import { ENABLE_PET_PHOTO_UPLOAD, ENABLE_UPDATE_DOG_PROFILE } from '@/config';
import { useAccount, useRecommendedAddons, useDogModal } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import {
  checkIfAllPetsAreDeceased,
  getPetsFulfillments,
  sortOrderByScheduled,
  sortPetsAlphabetically,
} from '@/utils';

const { logger } = datadogLogs;

export const DogsTab = ({ navigation, route }: ProtectedScreenProps<'Dogs'>) => {
  const account = useAccount();

  const [selectedPet, setSelectedPet] = useState<Pet>();
  const allPetsAreDeceased = checkIfAllPetsAreDeceased(account.pets);
  const sortedPets = useMemo(() => sortPetsAlphabetically(account.pets), [account.pets]);
  const filteredPets = useMemo(
    () => sortedPets.filter((pet) => pet.status !== PetStatus.DECEASED_TO_NOT_LIST),
    [sortedPets]
  );
  const activePets = useMemo(
    () => filteredPets.filter((pet) => pet.status === PetStatus.ACTIVE),
    [filteredPets]
  );

  const availableAddons = useRecommendedAddons();

  const petsFulfillments = getPetsFulfillments(
    [...account.orders.past, ...account.orders.processing],
    selectedPet
  );

  const { Modal: AddDogModal, setShowModal: setShowAddDogModal } = useDogModal('add');
  const { Modal: UpdateDogModal, setShowModal: setShowUpdateDogModal } = useDogModal('update');
  const [localStorageChecked, setLocalStorageChecked] = useState(false);

  const setPetFromLocalStorage = (): Pet | undefined => {
    if (localStorageChecked) {
      return;
    }
    setLocalStorageChecked(true);
    const requestedUrl = localStorage.getItem('requestedUrl');

    if (requestedUrl) {
      localStorage.removeItem('requestedUrl');
      const url = new URL(requestedUrl);
      if (url.searchParams.has('petId')) {
        const petId = url.searchParams.get('petId');
        const requestedPet = filteredPets.find((pet) => pet.id === petId);
        if (requestedPet) {
          setSelectedPet(requestedPet);
          navigation.setParams({ petId: requestedPet.id });
          return requestedPet;
        } else {
          logger.warn('Deeplink Failure: requestedUrl petId not found in pets', { petId });
        }
      }
    }
  };

  // select the first pet (or URL param pet if exists) on focus change
  useFocusEffect(
    useCallback(() => {
      const localPet = setPetFromLocalStorage();
      if (!localPet && filteredPets.length && !allPetsAreDeceased) {
        const petByParam = filteredPets.find((pet) => pet.id === route.params?.petId);
        const pet = petByParam || activePets[0] || filteredPets[0];
        setSelectedPet(pet);
        //  Need to additionally set here as well as hook to allow reloads to work
        navigation.setParams({ petId: pet?.id });
      }
    }, [activePets, allPetsAreDeceased, filteredPets])
  );

  useEffect(() => {
    navigation.setParams({ petId: selectedPet?.id });
  }, [selectedPet]);

  useEffect(() => {
    if (route.params?.scrollToTop) {
      window.scrollTo(0, 0);
    }
    if (route.params?.componentToRedirect === 'AdjustPortionSize' && route.params.petId) {
      navigation.push('AdjustPortionSize', {
        petId: route.params.petId,
        successRedirect: 'Dogs',
      });
    }
  }, [
    navigation,
    route.params?.componentToRedirect,
    route.params?.petId,
    route.params?.scrollToTop,
  ]);

  if (allPetsAreDeceased) {
    return (
      <PortalWrapper>
        <DeceasedDogsView />
      </PortalWrapper>
    );
  }

  if (!selectedPet) return null;

  const cancelledPets = filteredPets
    .filter((pet) => pet.status === PetStatus.CANCELLED)
    .map((pet) => ({
      name: pet.name,
      label: pet.name,
      id: pet.id,
      type: 'inactive',
    }));

  const dogItems = activePets
    .map((pet) => ({
      name: pet.name,
      label: pet.name,
      id: pet.id,
    }))
    .concat(cancelledPets);
  const selectedPetIsInactive = INACTIVE_STATUSES.includes(selectedPet.status);

  const petPlan = selectedPet
    ? account.petplans.find(({ pet: petId }) => petId === selectedPet.id)
    : undefined;
  const futureOrders = [...account.orders.processing, ...account.orders.upcoming]
    .filter((order) => !order.charged)
    .sort(sortOrderByScheduled);
  const nextOrder = futureOrders.find((order) =>
    order.products.some((product) => product.pet_plan?.id === petPlan?.id)
  );
  const petIsActive = selectedPet?.status === PetStatus.ACTIVE;

  const petLifestyleAvailable = selectedPet?.properties?.lifestyle !== null;
  const petBirthYearAvailable = selectedPet?.birth_year !== null;
  const petBreedAvailable = selectedPet?.breed_details && selectedPet.breed_details.length > 0;
  const petWeightAvailable = selectedPet?.properties?.weight;
  const allPetDataAvailable =
    selectedPet &&
    petBreedAvailable &&
    petBirthYearAvailable &&
    petWeightAvailable &&
    petLifestyleAvailable;

  if (!selectedPet || !petPlan) {
    return <DogTabSkeleton />;
  }

  const onPressAddNewDog = () => {
    segment.trackEvent('Click Add New Dog', { email: account.email, account_id: account.id });
    if (filteredPets.length < 8) {
      navigation.navigate('AddNewDog');
      return;
    }
    setShowAddDogModal(true);
  };

  const onPressUpdateDog = () => {
    segment.trackEvent('Click Update Dog Details', {
      email: account.email,
      account_id: account.id,
      pet_id: selectedPet.id,
      pet_name: selectedPet.name,
    });

    if (ENABLE_UPDATE_DOG_PROFILE === 'true') {
      navigation.navigate('UpdateDogProfile', { petId: selectedPet.id, successRedirect: 'Dogs' });
      return;
    }
    setShowUpdateDogModal(true);
  };

  return (
    <>
      <AddNewDogButton onPress={onPressAddNewDog} />

      <PillCarousel
        selectedItem={{ ...selectedPet, label: selectedPet.name }}
        setSelectedItemId={(petId) => {
          const pet = filteredPets.find((pet) => pet.id === petId);
          if (pet) {
            setSelectedPet(pet);
            navigation.setParams({ petId });
          }
        }}
        selectedItemIsInactive={selectedPetIsInactive}
        items={dogItems}
      />

      <PortalWrapper>
        {ENABLE_PET_PHOTO_UPLOAD === 'true' ? (
          <AvatarImageLarge
            petId={selectedPet.id}
            petGender={selectedPet.gender}
            petImageUrl={selectedPet.image_url}
          />
        ) : null}
        <Stack space={{ base: 4, lg: 6 }} justifyContent="center" alignItems="center" w="100%">
          <Stack
            alignItems="center"
            space={{ base: '24px', lg: '48px' }}
            w={{ base: '100%', lg: '344px' }}
          />
          {allPetDataAvailable && (
            <>
              <DogPictoGroup selectedPet={selectedPet} isDisabled={!petIsActive} />
              {petIsActive && (
                <Stack>
                  <Button onPress={onPressUpdateDog} variant="underlineMini">
                    {`Update ${selectedPet.name}'s Profile`}
                  </Button>
                </Stack>
              )}
            </>
          )}
          <Divider bg="gallery" />
          {nextOrder && (
            <EditableCard
              heading="Next Order"
              onPress={() =>
                navigation.navigate('Orders', {
                  orderId: nextOrder.id,
                  successRedirect: 'Dogs',
                })
              }
            >
              <HStack alignSelf="center">
                <Text size="bodyMd">{dayjs(nextOrder.scheduled).local().format('ddd, MMM D')}</Text>
              </HStack>
            </EditableCard>
          )}
          <PlanSummaryCard
            key={selectedPet.image_url}
            pet={selectedPet}
            petPlan={petPlan}
            disabled={!petIsActive}
          />
          {selectedPet && petsFulfillments.length ? (
            <FeedingGuidelinesCard petId={selectedPet.id} />
          ) : null}
          {petIsActive ? (
            <AddOnCarousel
              availableAddons={availableAddons}
              selectedPetIds={selectedPet.id ? [selectedPet.id] : undefined}
              showEditQuantity={false}
            />
          ) : null}
        </Stack>
        {!petIsActive ? (
          <Stack
            position="sticky"
            w="100%"
            bottom="0"
            left="0"
            right="0"
            alignItems="center"
            px={4}
            py={6}
            space={{ base: 2, lg: 6 }}
            bg="white"
          >
            <Text fontWeight="bold" size="bodyMdToLg" textAlign="center">
              Don't worry, you can make changes to your plan before confirming reactivation.
            </Text>
            <Button
              onPress={() => navigation.navigate('ReactivatePlan', { petId: selectedPet.id })}
            >
              REACTIVATE
            </Button>
          </Stack>
        ) : null}
      </PortalWrapper>
      <AddDogModal />
      <UpdateDogModal />
    </>
  );
};
